import React from 'react';
import { Container, Button } from 'semantic-ui-react';
import './Footer.css';

const Footer = () => {
    return (
        <footer role="contentinfo" className='footer-container'> {/* Added footer tag and role for semantic meaning */}
            <Container textAlign='center'>
                <p>
                    Phone: <a href="tel:+14693522098" aria-label="Call us at 469-352-2098">469-352-2098</a>
                </p>
                <p>
                    Email: <a href="mailto:book@yestoevents.co" aria-label="Email us at book@yestoevents.co">book@yestoevents.co</a>
                </p>
                <Button
                    as="a"
                    href="https://www.instagram.com/yes_to_events"
                    icon="instagram"
                    content="Follow us on Instagram"
                    aria-label="Follow us on Instagram"
                />
                <Button
                    as="a"
                    href="https://www.facebook.com/profile.php?id=100071096297605"
                    icon="facebook"
                    content="Follow us on Facebook"
                    aria-label="Follow us on Facebook"
                />
                <Button
                    as="a"
                    href="https://g.page/r/CbbviDu6RR4_EAI/review"
                    icon="google"
                    content="Rate us on Google"
                    aria-label="Rate us on Google"
                />
            </Container>
        </footer>
    );
};

export default Footer;
