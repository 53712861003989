import React from 'react';
import './PricingPage.css';

const PricingPage = () => {
    return (
        <div className="pricing-container">
            {/* Garlands */}
            <div className="pricing-section left-img">
                <div className="image-container">
                    <img src="./body_bar_yes_to_events.JPG" alt="Elegant Garlands" className="product-img" />
                    <img src="./simplegar.JPG" alt="Elegant Garlands" className="product-img" />
                    {/* Optionally render the second image */}
                </div>
                <div className="text-content">
                    <h2>Tailor-Made Garlands</h2>
                    <div className="pricing-info">
                        <p>Starting at just $15 per foot</p>
                        <p>*<strong>Install Minimum:</strong> Weekdays: $300, Weekends: $350 (Price is the required total of setup with install.)</p>
                    </div>
                    <div className="product-info">
                        <h3>Why Choose Our Garlands?</h3>
                        <ul>
                            <li><strong>Personalized Elegance:</strong> Custom lengths and colors to match your vision</li>
                            <li><strong>Full-Service Luxury:</strong> We offer both delivery and professional installation</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Add-ons */}
            <div className="pricing-section right-img">
                <div className="image-container">
                    <img src="./mickey.JPG" alt="Add-ons" className="product-img" />
                    <img src="./4yearmylar.jpg" alt="Add-ons" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>Add-ons...</h2>

                    {/* Add these lines for your three-column layout */}
                    <div className="addons-info">
                        <div className="addons-column">
                            <h3>Unleash the Magic with Premium Mylars</h3>
                            <ul>
                                <li><strong>Affordable Luxury:</strong> Prices start as low as $9.99, going up to $29.99 for exclusive designs</li>
                                <li><strong>Versatile Styles:</strong> From stars and hearts to animals—set the mood for any themed event</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Capture Attention with Dynamic Starbursts</h3>
                            <ul>
                                <li><strong>Value-Packed Pricing:</strong> Choose from Small at $20 or Big at $25</li>
                                <li><strong>Long-Lasting Quality:</strong> Crafted to withstand the elements for enduring appeal</li>
                            </ul>
                        </div>
                    </div>
                    {/* End of your three-column layout */}

                </div>
            </div>

            <div className="pricing-section left-img">
                <div className="image-container">
                    <img src="./jumbo.jpg" alt="Add-ons" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>More Add-ons...</h2>

                    {/* Add these lines for your three-column layout */}
                    <div className="addons-info">
                        <div className="addons-column">
                            <h3>Elevate Your Event with Standard Jumbo Balloons</h3>
                            <ul>
                                <li><strong>Affordable Pricing:</strong> 24" for just $15, 36" for only $20</li>
                                <li><strong>Color Choices:</strong> Pick from an array of vibrant colors and eye-catching prints</li>
                                <li><strong>Maximum Impact:</strong> Designed for optimum visibility in expansive venues</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Make a Statement with Jumbo Helium Balloons</h3>
                            <ul>
                                <li><strong>Starting Price:</strong> An unbeatable $55</li>
                                <li><strong>Sizes:</strong> Choose between a grand 36" or a sizable 24" diameter</li>
                                <li><strong>Visibility:</strong> Perfect for filling large spaces and grabbing attention from afar</li>
                                <li><strong>Customization:</strong> Visit our calculator for tailored pricing and options</li>
                            </ul>
                        </div>
                    </div>
                    {/* End of your three-column layout */}

                </div>
            </div>


            <div className="pricing-section right-img">
                <div className="image-container">
                    <img src="./branding.JPG" alt="Add-ons" className="product-img" />
                    <img src="./branding1.JPG" alt="Add-ons" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>Services</h2>

                    {/* Add these lines for your three-column layout */}
                    <div className="addons-info">
                        <div className="addons-column">
                            <h3>Turn Your Brand Into An Experience</h3>
                            <ul>
                                <li><strong>Cost-Effective Sophistication:</strong> Packages begin at just $19.99, scalable to your needs</li>
                                <li><strong>Vivid Versatility:</strong> From corporate logos to custom messages, make a memorable impact</li>
                                <li><strong>Branding that Pops:</strong> Elevate your event with branded balloons that are far from ordinary</li>
                                <li><strong>Longevity Meets Luxury:</strong> Durable material ensures your branding outlives the event itself</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Expert Installation Services</h3>
                            <ul>
                                <li><strong>Transparent Costs:</strong> Pricing starts at $49.99, scaled according to project complexity</li>
                                <li><strong>Precision Execution:</strong> Our skilled team ensures a flawless setup for both indoor and outdoor venues</li>
                                <li><strong>Weather-Resilient:</strong> Built to withstand various weather conditions for a worry-free event</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Convenient Delivery Options</h3>
                            <ul>
                                <li><strong>Cost-Effective:</strong> Complimentary delivery for the first 10 miles, then just $4 per additional mile</li>
                                <li><strong>Timely Arrival:</strong> Dependable service ensures your balloons arrive when you need them</li>
                                <li><strong>Safe Handling:</strong> Expert care to ensure your balloons arrive in perfect condition</li>
                                <li><strong>Flexible Scheduling:</strong> Choose a delivery window that fits your event’s timeline</li>
                            </ul>
                        </div>
                    </div>
                    {/* End of your three-column layout */}

                </div>
            </div>
            <div className="pricing-section left-img">
                <div className="image-container">
                    <img src="./maintain.JPG" alt="Add-ons" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>Services</h2>

                    {/* Add these lines for your three-column layout */}
                    <div className="addons-info">
                        <div className="addons-column">
                            <h3>Balloon Maintenance</h3>
                            <ul>
                                <li><strong>Affordable Care:</strong> Maintenance packages start at just $150 per day</li>
                                <li><strong>Typical Lifespan:</strong> Under normal conditions, outdoor balloons last up to 4 days, while indoor setups can last up to a month</li>
                                <li><strong>Extended Maintenance:</strong> If your event requires longer-lasting setups or faces challenging weather conditions, we offer specialized maintenance options</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Effortless Cleanup Service</h3>
                            <ul>
                                <li><strong>Cost-Effective:</strong> Cleanup packages begin at $150</li>
                                <li><strong>Event Scale:</strong> We handle cleanup for events of all sizes, from small gatherings to large venues</li>
                                <li><strong>Thorough Service:</strong> Complete removal and disposal of all balloon materials, leaving the space as good as new</li>
                            </ul>
                        </div>
                    </div>
                    {/* End of your three-column layout */}
                </div>
            </div>

            <div className="pricing-section right-img">
                <div className="image-container">
                    <img src="./season.JPG" alt="Add-ons" className="product-img" />
                    <img src="./season2.JPG" alt="Add-ons" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>Seasonal Balloon Packages</h2>

                    {/* Three-column layout for add-ons */}
                    <div className="addons-info">
                        <div className="addons-column">
                            <h3>5-Foot Take & Go Garland</h3>
                            <ul>
                                <li><strong>Luxury on a Budget:</strong> Only $75</li>
                                <li>Comes with Mylar balloons and theme-appropriate accents.</li>
                                <li>Included hanging kit simplifies DIY setup.</li>
                                <li>Multiple customization options for a unique touch.</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>10-Foot Take & Go Garland</h3>
                            <ul>
                                <li><strong>Double the Beauty, Double the Value:</strong> $150</li>
                                <li>Features Mylar balloons and themed additions.</li>
                                <li>Easy setup with provided hanging kit.</li>
                                <li>Further customization available for added charm.</li>
                            </ul>
                        </div>
                        <div className="addons-column">
                            <h3>Helium Balloon Bouquets</h3>
                            <ul>
                                <li><strong>Flexible Pricing:</strong> Small starts at $49.50, Medium at $99.50, Large at $199.99</li>
                                <li>Choose from a vibrant array of colors and prints to tailor your surprise.</li>
                                <li>Perfect for birthdays, anniversaries, or just because.</li>
                                <li>Deliver smiles and brighten someone's day instantly.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="pricing-section left-img">
                <div className="image-container">
                    <img src="./referral.jpg" alt="Returning Customer and Referral Appreciation" className="product-img" />
                </div>
                <div className="text-content">
                    <h2>Rewards and Referrals</h2>
                    <div className="pricing-info">
                        <h3>Your Exclusive Discounts</h3>
                        <p>Enjoy 5% off on your next purchase and earn even more with our referral program.</p>
                    </div>
                    <div className="product-info">
                        <h3>Why You'll Love Coming Back</h3>
                        <ul>
                            <li>Your loyalty means the world to us; that's why we offer exclusive discounts as a token of our appreciation.</li>
                            <li>Don't miss out on limited-time promotions and special offers reserved just for you.</li>
                            <li>Refer a friend and receive an additional 5% discount when they make their first purchase!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingPage;
