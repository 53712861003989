import React, { useState } from 'react';
import { Button, Container, Input } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import './ServiceCalculator.css'

const ServiceCalculator = () => {
    const [selectedServices, setSelectedServices] = useState({});
    const [selectedAdditionalServices, setSelectedAdditionalServices] = useState({});
    const [selectedHeliumServices, setSelectedHeliumServices] = useState({});

    const services = [
        { name: 'Balloon Garlands Per 5 foot', price: 75 },
        { name: '24 Inch Balloon', price: 15 },
        { name: '36 Inch Balloon', price: 20 },
        { name: 'Mylar Small', price: 10 },
        { name: 'Mylar Medium', price: 20 },
        { name: 'Mylar Large', price: 30 },
        { name: 'Starburst Small', price: 15 },
        { name: 'Starburst Large', price: 20 },
    ];

    const additionalServices = [
        { name: 'Delivery (Up to 10 Miles)', price: 0 },
        { name: 'Delivery Mileage Overage per mile if greater than 10 miles from Princeton, Tx', price: 4 },
        { name: 'Installation Per 5ft of garland (minimum 5 ft of garland)', price: 50 },
        { name: 'Custom Branding Per Branding (Doesnt include balloon)', price: 20 },
        { name: 'Cleanup', price: 150 },
    ];

    const heliumServices = [
        { name: 'Jumbo Helium Balloons 24 Inch', price: 55 },
        { name: 'Jumbo Helium Balloons 24 Inch With Tassel', price: 65 },
        { name: 'Jumbo Helium Balloons 24 Inch With Confetti In-side and Tassel', price: 75 },
        { name: 'Jumbo Helium Balloons 24 Inch With Custom Branding and Tassel', price: 80 },
        { name: 'Jumbo Helium Balloons 24 Inch With Custom Branding, Tassel, and Confetti', price: 98 },
        { name: 'Jumbo Helium Balloons 24 Inch With Custom Painting, and Tassel', price: 115 },
        { name: 'Jumbo Helium Balloons 36 Inch', price: 65 },
        { name: 'Jumbo Helium Balloons 36 Inch With Tassel', price: 75 },
        { name: 'Jumbo Helium Balloons 36 Inch With Confetti In-side and Tassel', price: 85 },
        { name: 'Jumbo Helium Balloons 36 Inch With Custom Branding and Tassel', price: 90 },
        { name: 'Jumbo Helium Balloons 36 Inch With Custom Branding, Tassel, and Confetti', price: 108 },
        { name: 'Jumbo Helium Balloons 36 Inch With Custom Painting, and Tassel', price: 125 },
    ];

    const incrementService = (service, type) => {
        const target = {
            main: selectedServices,
            additional: selectedAdditionalServices,
            helium: selectedHeliumServices,
        }[type];
        const setter = {
            main: setSelectedServices,
            additional: setSelectedAdditionalServices,
            helium: setSelectedHeliumServices,
        }[type];

        setter({
            ...target,
            [service.name]: (target[service.name] || 0) + 1,
        });
    };

    const decrementService = (service, type) => {
        const target = {
            main: selectedServices,
            additional: selectedAdditionalServices,
            helium: selectedHeliumServices,
        }[type];
        const setter = {
            main: setSelectedServices,
            additional: setSelectedAdditionalServices,
            helium: setSelectedHeliumServices,
        }[type];

        if (target[service.name] > 0) {
            setter({
                ...target,
                [service.name]: target[service.name] - 1,
            });
        }
    };

    const calculateTotal = (serviceList, selectedList) => {
        return Object.keys(selectedList).reduce((total, serviceName) => {
            return total + serviceList.find((s) => s.name === serviceName).price * selectedList[serviceName];
        }, 0);
    };

    const updateServiceQuantity = (service, quantity, type) => {
        const target = {
            main: selectedServices,
            additional: selectedAdditionalServices,
            helium: selectedHeliumServices,
        }[type];
        const setter = {
            main: setSelectedServices,
            additional: setSelectedAdditionalServices,
            helium: setSelectedHeliumServices,
        }[type];

        if (quantity >= 0) {
            setter({
                ...target,
                [service.name]: parseInt(quantity, 10),
            });
        }
    };

    const clearAll = () => {
        setSelectedServices({});
        setSelectedAdditionalServices({});
        setSelectedHeliumServices({});
    };


    return (
        <Container>
            <Helmet>
                <title>Service Calculator - Yes To Events</title>
                <meta name="description" content="Calculate the cost of balloon artistry" />
                <meta name="keywords" content="Balloon Services, Helium Services, Service Calculator, YourLocation" />
                {/* For local SEO */}
                <meta name="geo.region" content="US-TX" />
                <meta name="geo.placename" content="McKinney" />
            </Helmet>
            <h2>Service Calculator</h2>
            <div className="service-section">
                {/* Main Services */}
                <h3>Main Services</h3>
                {services.map((service) => (
                    <div className="service-item" key={service.name}>
                        <span>{service.name} - ${service.price}</span>
                        <Button onClick={() => decrementService(service, 'main')}>-</Button>
                        <Input
                            type="number"
                            value={selectedServices[service.name] || 0}
                            onChange={(e) => updateServiceQuantity(service, e.target.value, 'main')}
                        />
                        <Button onClick={() => incrementService(service, 'main')}>+</Button>
                    </div>
                ))}
                <h3>Total Cost: ${calculateTotal(services, selectedServices)}</h3>
            </div>
            <div className="service-section">
                <h3>Additional Services</h3>
                {additionalServices.map((service) => (
                    <div className="service-item" key={service.name}>
                        <span>{service.name} - ${service.price}</span>
                        <Button onClick={() => decrementService(service, 'additional')}>-</Button>
                        <Input
                            type="number"
                            value={selectedAdditionalServices[service.name] || 0}
                            onChange={(e) => updateServiceQuantity(service, e.target.value, 'additional')}
                        />
                        <Button onClick={() => incrementService(service, 'additional')}>+</Button>
                    </div>
                ))}
                <h3>Total Additional Cost: ${calculateTotal(additionalServices, selectedAdditionalServices)}</h3>
            </div>
            {/* Helium Services */}
            <div className="service-section">
                <h3>Helium Services</h3>
                {heliumServices.map((service) => (
                    <div className="service-item" key={service.name}>
                        <span>{service.name} - ${service.price}</span>
                        <Button onClick={() => decrementService(service, 'helium')}>-</Button>
                        <Input
                            type="number"
                            value={selectedHeliumServices[service.name] || 0}
                            onChange={(e) => updateServiceQuantity(service, e.target.value, 'helium')}
                        />
                        <Button onClick={() => incrementService(service, 'helium')}>+</Button>
                    </div>
                ))}
                <h3>Total Helium Cost: ${calculateTotal(heliumServices, selectedHeliumServices)}</h3>
            </div>

            <h3>Grand Total: ${calculateTotal(services, selectedServices) + calculateTotal(additionalServices, selectedAdditionalServices) + calculateTotal(heliumServices, selectedHeliumServices)}</h3>
            <Button onClick={clearAll}>Clear</Button>
        </Container>
    );
};

export default ServiceCalculator;