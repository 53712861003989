import './index.css'
import React, { useState, useEffect } from 'react';
import { Container, Grid, Header as SemanticHeader, Form, Dropdown, Button, Image, Search, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios';
import _ from 'lodash';
const Contact = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormState);
    const [suggestions, setSuggestions] = useState([]);
    const [eventLocation, setEventLocation] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const debouncedSave = _.debounce(async (query) => {
        try {
            const res = await axios.get(`https://yestoevents.net/api/places?query=${query}`);
            const data = res.data;
            setSuggestions(data);
        } catch (error) {
            console.error('Error fetching location suggestions', error);
        }
    }, 300);

    const validateForm = () => {
        const newErrors = {};

        // Required field validations
        ['firstName', 'lastName', 'email', 'phone', 'eventType', 'budget', 'eventDate', 'eventTime', 'eventLocation', 'howDidYouHear', 'message'].forEach(field => {
            if (!formData[field]) newErrors[field] = `${field} is required`;
        });

        // Email format validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (formData.email && !emailPattern.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        // File type validation for image upload
        if (formData.uploadFile) {
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if (!allowedExtensions.exec(formData.uploadFile.name)) {
                newErrors.uploadFile = 'Invalid file type. Only JPG, JPEG, PNG, and GIF are allowed.';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleChange = async (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
        if (name === 'eventLocation') {
            setEventLocation(value);
            debouncedSave(value);
        }
    };

    const handleSearchChange = async (e, { value }) => {
        setEventLocation(value);

        try {
            const response = await fetch(`https://yestoevents.net/api/places?query=${value}`);
            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            console.error("Error fetching places:", error);
        }
    };

    const handleLocationSelect = (value) => {
        setEventLocation(value);
        setFormData({ ...formData, eventLocation: value });
    };

    const handleFileChange = (e) => setFormData({ ...formData, uploadFile: e.target.files[0] });

    const handleRecaptchaChange = (value) => setFormData({ ...formData, recaptchaValue: value });


    const timeToMinutes = (timeString) => {
        const [hour, minute] = timeString.split(':').map(Number);
        return hour * 60 + minute;
    };
    const handleSubmit = async () => {
        setIsLoading(true);
        const currentDate = new Date();
        const eventDate = new Date(formData.eventDate); // Assuming formData.eventDate is in a compatible format

        // Check if event date is at least one day in the future
        const isFutureDate = eventDate.setHours(0,0,0,0) > currentDate.setHours(0,0,0,0);

        if (!isFutureDate) {
            const currentTime = currentDate.toTimeString().split(' ')[0];
            const currentMinutes = timeToMinutes(currentTime);
            const eventMinutes = timeToMinutes(formData.eventTime);

            if (eventMinutes < currentMinutes) {
                alert('Event time cannot be in the past.');
                return;
            }
        }
        const uploadData = new FormData();
        Object.keys(formData).forEach((key) => uploadData.append(key, formData[key]));
        if (!validateForm()) {
            return;
        }
        try {
            const response = await axios.post('https://yestoevents.net/submit', uploadData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.data && response.data.message) navigate('/success');
        } catch (error) {
            alert('An error occurred while submitting the form. Please try again.');
        }
    };

    const currentDate = new Date();
    const minDate = currentDate.toISOString().split('T')[0];
    const currentTime = currentDate.toTimeString().split(' ')[0];
    const minTime = currentTime;

    return (
        <Container className="contact-container">
            <Helmet>
                <title>Contact Us - YesToEvents</title>
                <meta name="description" content="Ready to make your event unforgettable? Contact us today!" />
            </Helmet>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={6} mobile={16} verticalAlign="middle">
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                            <Image src="contact-person.webp" alt="We'd love to hear from you!" />
                            <p style={{ color: 'white' }}>We'd love to hear from you!</p>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={10} mobile={16}>
                        <SemanticHeader as="h1" style={{ color: 'white' }}>Contact Us</SemanticHeader>
                        <p style={{ color: 'white' }}>
                            Ready to make your event unforgettable? At YesToEvents, we specialize in creating breathtaking balloon decorations to suit any occasion.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    name='firstName'
                                    label='First Name'
                                    onChange={handleChange}
                                    error={errors.firstName ? { content: errors.firstName } : null}
                                />
                                <Form.Input
                                    name='lastName'
                                    label='Last Name'
                                    onChange={handleChange}
                                    error={errors.lastName ? { content: errors.lastName } : null}
                                />
                            </Form.Group>
                            <Form.Input
                                type='email'
                                name='email'
                                label='Email'
                                onChange={handleChange}
                                error={errors.email ? { content: errors.email } : null}
                                />
                            <Form.Input type="tel" name="phone" label="Phone" onChange={handleChange} />
                            <Form.Field>
                                <label>Event Type</label>
                                <Dropdown name="eventType" options={eventTypeOptions} selection onChange={handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>Budget</label>
                                <Dropdown name="budget" options={budgetOptions} selection onChange={handleChange} />
                            </Form.Field>
                            <Form.Group widths="equal">
                                <Form.Input name="eventDate" label="Event Date" type="date" min={minDate} onChange={handleChange} />
                                <Form.Input name="eventTime" label="Event Time" type="time" onChange={handleChange} />
                            </Form.Group>
                            <Form.Field>
                                <label>Event Location</label>
                                <Search
                                    onSearchChange={handleSearchChange} // function to call your API for suggestions
                                    results={suggestions.map((suggestion, i) => ({
                                        key: i,
                                        title: suggestion,
                                    }))}
                                    value={eventLocation}
                                    onResultSelect={(e, data) =>
                                        handleLocationSelect(data.result.title)
                                    }
                                />
                            </Form.Field>
                            <Form.Input name="howDidYouHear" label="How did you hear about us?" onChange={handleChange} />
                            <Form.Input
                                type='file'
                                name='uploadFile'
                                label='Upload an inspirational photo. (Only JPG, JPEG, PNG, and GIF are allowed.)'
                                onChange={handleFileChange}
                                error={errors.uploadFile ? { content: errors.uploadFile } : null}
                            />
                            <Form.TextArea name="message" label="Message" onChange={handleChange} />
                            <ReCAPTCHA sitekey="6Lc8LdUnAAAAAMG6qNzFpEl8I7g-6UcL5L9ilsWW" onChange={handleRecaptchaChange} />
                            {isLoading && <Loader active inline='centered' />}
                            <Button
                                type="submit"
                                disabled={!formData.recaptchaValue || isLoading}  // add isLoading condition
                                style={{ backgroundColor: '#FEC7BC', color: 'white' }}
                            >
                                Submit
                            </Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

const initialFormState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    budget: '',
    eventType: '',
    eventDate: '',
    eventTime: '',
    eventLocation: '',
    howDidYouHear: '',
    uploadFile: null,
    message: '',
    recaptchaValue: '',
};

const eventTypeOptions = [
    { key: 'birthday', value: 'birthday', text: 'Birthday' },
    { key: 'baby-shower', value: 'baby-shower', text: 'Baby Shower' },
    { key: 'bridal-shower', value: 'bridal-shower', text: 'Bridal Shower' },
    { key: 'graduation-party', value: 'graduation-party', text: 'Graduation Party' },
    // Other event type options
];

const budgetOptions = [
    { key: 'less-than-300', value: 'less-than-300', text: 'Less Than $300' },
    { key: '300-to-500', value: '300-to-500', text: 'From $300 to $500' },
    { key: '500-to-1000', value: '500-to-1000', text: 'From $500 to $1000' },
    { key: 'greater-than-1000', value: 'greater-than-1000', text: 'More than $1000' },
    // Other budget type options
];

export default Contact;