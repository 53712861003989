import React, { useState } from 'react';
import { Container, Header, Card, Image, Modal, Button, Divider } from 'semantic-ui-react';import Slider from 'react-slick';
import './Services.css';
import AdditionalServices from "./AdditionalServices";
import ServiceCalculator from "./ServiceCalculator";
import TermsAndConditions from "./TermsAndConditions";
import {Helmet} from "react-helmet";

const Services = () => {
    const [openModal, setOpenModal] = useState(null);
    const [activeImages, setActiveImages] = useState([]);
    const openServiceModal = (index) => {
        setOpenModal(index);
    };

    const closeServiceModal = () => {
        setOpenModal(null);
    };

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const imagesPerSlide = 2;
    const nextImage = (length) => {
        setCurrentImageIndex((prevIndex) => {
            return (prevIndex + 1) % length;
        });
    };

    const prevImage = (length) => {
        setCurrentImageIndex((prevIndex) => {
            return (prevIndex - 1 + length) % length;
        });
    };


    return (
        <Container className="services-container">
            <Helmet>
                <title>Calculator - YesToEvents</title>
                <meta name="description" content="Calculate pricing for your balloon set up" />
            </Helmet>
            <Header as='h1' className="header-title">Our Service Calculator</Header>
            <p className="header-description">
                At YesToEvents, we offer a variety of products and service and want to offer transparent pricing.
            </p>
            <p className="header-description">
                NOTE: Mylars(Numbers, Characters, etc.) vary a bunch in size and configuration and will be priced separately.
            </p>
            <div className="service-calculator-section">
                <Header as="h2">Estimate Your Cost</Header>
                <ServiceCalculator />
            </div>
            <div className="book-now-section">
                <Header as="h2" className="book-now-title">Ready to make your event special?</Header>
                <p className="book-now-description">Click the button below to book our services now!</p>
                <Button as="a" href="/contact" className="book-now-button">Book Now</Button>
            </div>
        </Container>
    );
};

export default Services;
