import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home/index';
import Services from './components/services/index'; // Import when ready
import Contact from './components/contact/index';
import Gallery from "./components/gallery/Gallery";
import SuccessPage from "./components/contact/SuccessPage";
import PricingPage from "./components/pricing/PricingPage";


const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} /> {/* Define Contact later */}
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/calculator" element={<Services />} /> {/* Define Services later */}
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
