import React from 'react';
import { Helmet } from "react-helmet"; // import Helmet
import { Button, Container, Header, Divider, Image } from 'semantic-ui-react';
import { JsonLd } from "react-schemaorg";
import './index.css';

const Home = () => {
    return (
        <Container className="home-container">
            {/* Add Helmet for SEO */}
            <Helmet>
                <title>YesToEvents - Transforming Celebrations with Custom Balloon Artistry</title>
                <meta name="description" content="YesToEvents specializes in custom-designed balloon artistry for all events. Turn your vision into reality with our unique decor solutions." />
                <meta name="keywords" content="balloon artistry, event decor, celebrations, YesToEvents" />
            </Helmet>
            <JsonLd
                itemScope
                itemType="http://schema.org/LocalBusiness"
                itemProp={{
                    name: "Yes To Events",
                    telephone: "+1-469-352-2098",
                    priceRange: "$$-$$$$",
                    description: "Yes To Events specializes in custom-designed balloon artistry for all events.",
                    address: {
                        "@type": "PostalAddress",
                        addressLocality: "McKinney",
                        addressRegion: "Texas",
                        postalCode: "75072",
                    },
                }}
            />
            <div className="top-section">
                <div className="overlay-text">
                    <Header as="h1">Welcome to YesToEvents: Your Local Experts in Balloon Artistry</Header>
                    <p>
                        Proudly serving Allen, Lucas, Plano, Wylie, Dallas, Frisco, Addison, Melissa, Prosper, McKinney, Princeton, Anna, Highland Park, and Las Colinas in Texas, YesToEvents is your go-to source for transformative balloon decor. Whether you're planning an intimate family gathering or a grand event, we bring your vision to life with custom designs.
                    </p>
                    <Button as="a" href="/pricing" aria-label="Explore our customized balloon artistry services in Allen, Lucas, Plano, and surrounding areas">Explore Our Pricing</Button>
                </div>
            </div>
            <Divider />
            <div className="bottom-section">
                <p>
                    Each event is an opportunity for something unforgettable. At YesToEvents, we specialize in creating visually stunning environments that set the mood and captivate your guests.
                </p>
                <Image.Group size='small'>
                    <Image src='./01D7D20F-FFD2-4DD5-91B3-4B53CF393BE2.webp' alt='Event featuring YesToEvents balloon artistry graduation party' />
                    <Image src='./DFD6C236-062E-4451-8FC4-F59316697119.webp' alt='Close-up of YesToEvents balloon design door arch' />
                    <Image src='./F0BD42BB-3229-4137-93B8-A29F06155CE0.webp' alt='Close-up of YesToEvents balloon design birthday party' />
                    <Image src='./BB510712-6041-4ED9-9195-A87E903ABA6E.webp' alt='Close-up of YesToEvents balloon design baby shower' />
                    {/* Add more images with alt text */}
                </Image.Group>
                <p>
                    We offer a variety of services from small balloon garlands to grand decorative backdrops. Our attention to detail ensures that your event will be not just a success, but a memorable experience for all involved.
                </p>
                <Button as="a" href="/contact" aria-label="Contact us to begin your event journey">Begin Your Journey With Us</Button>
            </div>
            <Divider />
            <div className="about-section">
                <Header as="h2">About Yes To Events</Header>
                <p>
                    With a rich legacy spanning over a decade, Yes To Events Balloon Artistry is your premier destination for exceptional balloon decor and event embellishments. We specialize in turning ordinary spaces into extraordinary experiences, catering to a diverse range of events from intimate gatherings to grand celebrations.<br/><br/>

                    Founded by Diana Ilash Conkle, a seasoned expert in balloon artistry, the company has grown to include a talented team of designers and installers. Diana's passion for impeccable design and a keen eye for detail have been the guiding principles for Yes To Events. Our team shares her commitment to elevating every event to an artistic masterpiece, ensuring each project's success.<br/><br/>

                    <strong>What Sets Us Apart:</strong><br/>
                    <ul class="tight-bullets">
                        <li><strong>Customized Solutions:</strong> Tailored designs that suit your specific theme and vision.</li>
                        <li><strong>Quality and Longevity:</strong> Our balloons are not only beautiful but also long-lasting, providing aesthetic appeal throughout your event.</li>
                        <li><strong>Safety and Compliance:</strong> We adhere to the highest safety standards during installation.</li>
                        <li><strong>Innovation:</strong> From classic designs to contemporary artistry, we continually innovate to stay ahead of trends.</li>
                        <li><strong>Team Expertise:</strong> A skilled team trained under Diana’s mentorship to bring your dream event to life.</li>
                    </ul>
                    <p>We believe that every event is a new opportunity for creativity and we thrive on making your special occasions unforgettable. Choose Yes To Events Balloon Artistry for an elevated event experience.</p>
                </p>
                <Button
                    as="a"
                    href="https://instagram.com/yes_to_events"
                    target="_blank"
                    rel="noopener noreferrer"
                    color='pink'
                    aria-label="See Diana's Work on Instagram"
                >
                    See Our Work
                </Button>
            </div>
        </Container>
    );
};

export default Home;
