import React from 'react';
import { Container, Header, Button } from 'semantic-ui-react';
import './SuccessPage.css';

const SuccessPage = () => {
    return (
        <Container className="success-container">
            <Header as="h1">Thank You for Reaching Out!</Header>
            <p>We've successfully received your inquiry. One of our team members will be in touch with you shortly.</p>
            <Button as="a" href="/" className="home-button">Back to Home</Button>
        </Container>
    );
};

export default SuccessPage;
