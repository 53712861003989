import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './Header.css';
import logo from './logo.png';  // Import your logo (if you plan to use it)

const Header = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            {windowWidth > 768 ? (
                <Menu className="main-header" role="navigation">
                    <Menu.Item position='left' as='a' href='tel:+14693522098' className='header-item cta' aria-label="Call us now">
                        <button className='ui primary button'>
                            <Icon name='phone' />
                            Call Now
                        </button>
                    </Menu.Item>
                    <div className="logo-text-container">
                        <div className="logo-text">Yes To Events</div>
                        <div className="logo-subtext">Balloons | Backdrops | More</div>
                    </div>
                    <Menu.Menu position='right'>
                        <Menu.Item as={Link} to="/" className="header-item cta" aria-label="Home page">
                            <button className="ui primary button">Home</button>
                        </Menu.Item>
                        <Menu.Item as={Link} to="/pricing" className="header-item cta" aria-label="Our pricing">
                            <button className="ui primary button">Pricing</button>
                        </Menu.Item>
                        <Menu.Item as={Link} to="/calculator" className="header-item cta" aria-label="Our pricing calculator">
                            <button className="ui primary button">Calculator</button>
                        </Menu.Item>
                        <Menu.Item as={Link} to="/contact" className="header-item cta" aria-label="Contact us">
                            <button className="ui primary button">Contact Us</button>
                        </Menu.Item>
                        <Menu.Item aria-label="Follow us on Instagram">
                            <a href="https://instagram.com/yes_to_events" target="_blank" rel="noopener noreferrer">
                                <Icon name='instagram' size='large' />
                            </a>
                        </Menu.Item>
                        <Menu.Item aria-label="Follow us on Facebook">
                            <a href="https://www.facebook.com/profile.php?id=100071096297605" target="_blank" rel="noopener noreferrer">
                                <Icon name='facebook' size='large' />
                            </a>
                        </Menu.Item>
                        <Menu.Item aria-label="Rate us on Google">
                            <a href="https://g.page/r/CbbviDu6RR4_EAI/review" target="_blank" rel="noopener noreferrer">
                                <Icon name='google' size='large' />
                            </a>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            ) : (
                <Menu compact className="mobile-header" role="navigation">
                    <div className="mobile-header">
                        <div className="mobile-logo-text-container">
                            <div className="mobile-logo-text">Yes To Events</div>
                        </div>
                        <button onClick={toggleMenu} className='mobile-dropdown' aria-label="Open menu">
                            <i className='bars icon'></i>
                        </button>
                        <div className={`full-screen-dropdown ${menuOpen ? 'visible' : ''}`}>
                            <div className="mobile-menu">
                                <Icon name='close' className='close-menu-button' onClick={() => setMenuOpen(false)} aria-label="Close menu" />
                                <Dropdown.Item as={Link} to="/" onClick={toggleMenu} aria-label="Home page">Home</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/pricing" onClick={toggleMenu} aria-label="Our pricing">Pricing</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/calculator" onClick={toggleMenu} aria-label="Our Pricing Calculator">Calculator</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/contact" onClick={toggleMenu} aria-label="Contact us">Contact Us</Dropdown.Item>
                            </div>
                        </div>
                    </div>
                </Menu>
            )}
        </>
    );
};

export default Header;
